import lottie from "lottie-web"
import emailAnimation from "../../animations/email.json"
import thankYouAnimation from "../../animations/thank-you.json"

const animationsLoaders = {
  emailAnimation: (id) => {
    const animation = lottie.loadAnimation({
      container: document.querySelector(`#${id}`),
      animationData: emailAnimation,
      renderer: "svg",
      loop: true,
      autoplay: true,
    })

    animation.setSpeed(0.8)

    // To avoid jittery animation, make a small delay before
    // the playback to wait until the view is fully rendered.
    setTimeout(() => {
      requestAnimationFrame(() => animation.play())
    }, 800)
  },
  thankYouAnimation: (id) => {
    lottie.loadAnimation({
      container: document.querySelector(`#${id}`),
      animationData: thankYouAnimation,
      renderer: "svg",
      loop: true,
      autoplay: true,
    })
  },
}

export default () => ({
  loadAnimationById(id) {
    const animationLoader = animationsLoaders[id]
    if (!animationLoader) {
      console.error(`Animation loader "${id}" not found`)
      return
    }

    // Element ID is passed to not hardcode it within loaders
    animationLoader(id)
  },
})
