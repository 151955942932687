import lottie from "lottie-web"

import { load, store } from "./localStorage"
import Bugsnag from "@bugsnag/js"
import smoothscroll from "smoothscroll-polyfill"

const KGS_IN_LBS = 0.453592
const LBS_IN_KG = 2.20462
const INCH_IN_CM = 2.54
const CM_IN_FT = 30.48
const LOCAL_STORAGE_KEY = "data"
const INVALID_DOMAIN_CODE = "invalid_domain"
const EMAIL_VALIDATION_ERROR = "email: Invalid email"
const EMAIL_VALIDATION_CODE = "email_verification_failed"

const round = Math.round
export const kgToLbs = (kg) => kg / KGS_IN_LBS
export const lbsToKg = (lbs) => lbs / LBS_IN_KG
export const cmToFtIn = (cm) => {
  const totalInch = cm / INCH_IN_CM
  const feet = Math.floor(totalInch / 12)
  const inch = totalInch - 12 * feet

  return { heightFt: Math.round(feet), heightIn: Math.round(inch) }
}

export const ftInToCm = ({ ft, inch }) => {
  const heightCm = Math.round(ft * CM_IN_FT + inch * INCH_IN_CM)

  return heightCm
}

function initialState() {
  return {
    // Globals
    token: null,
    measurementSystem: pipelineConfig.measurementSystem,
    domain: null,

    // Pipe form
    desiredGoal: null,
    howLongSinceIdealWeight: null,
    whyLoseWeight: [],
    defineYourDiet: null,
    foodAllergies: [],
    menopause: null,
    weightLossKnowledge: null,
    physicallyActive: null,
    applyToYou: [],
    applyTosnackingTriggersYou: [],
    diseases: [],
    mood: null,
    howBusy: null,
    mealPrep: null,
    age: null,
    heightFt: null,
    heightIn: null,
    heightCm: null,
    weightKg: null,
    weightLbs: null,
    helpAfterMenopause: null,
    targetWeightKg: null,
    targetWeightLbs: null,
    firstName: null,
    flushesAndSweating: null,
    dryerSkin: null,
    hormonalTherapy: null,
    email: null,
    privacyAgreement: false,
    cookiePolicyBannerAccepted: false,
  }
}

export default {
  // Push data store to backend API
  init() {
    const data = load(LOCAL_STORAGE_KEY)

    if (data && data.values) {
      this.values = data.values
    } else {
      this.values = initialState()
    }

    if (!this.values?.activeOffer) {
      const pipelineOffers = pipelineConfig.offers
      this.values.activeOffer = pipelineOffers.find(
        (offer) => offer.customData.default_select === "true"
      )
    }

    this.storeAnalytics()

    Alpine.effect(() => {
      store(LOCAL_STORAGE_KEY, { values: this.values })
    })
  },

  storeAnalytics() {
    const analyticsData = {}

    analyticsData.domain = document.location.host

    // Add cookies - _ga, _gid, _fbc, _fbp, ...
    document.cookie.split("; ").forEach((item) => {
      const [key, value] = item.split("=")
      analyticsData[key] = value
    })

    // Add search params - fbclid, utm_*, ...
    const url = new URL(document.location)
    url.searchParams.forEach((value, key) => {
      analyticsData[key] = value
    })

    // Add FraudNet data
    analyticsData["fraud_net_session_id"] = localStorage.getItem("fraud_net_session_id") || ""

    this.values = { ...this.values, ...analyticsData }
  },

  writeToLocalStorage(data) {
    window.localStorage.setItem(
      `${pipelineConfig.pathPrefix}:${LOCAL_STORAGE_KEY}`,
      JSON.stringify({ values: data })
    )
  },

  async pull() {
    if (this.token) {
      const res = await fetch(pipelineConfig.orderApiUrl, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          "x-token": this.token,
        },
      })
      const data = await res.json()

      Object.entries(data).forEach(([key, value]) => {
        this[key] = value
      })

      this.writeToLocalStorage({
        ...data,
      })
    }
  },

  // Push data store to backend API
  async push() {
    const apiUrl = pipelineConfig.orderApiUrl
    const options = {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(this.values),
    }

    if (this.values.token) {
      options.method = "PATCH"
      options.headers["x-token"] = this.values.token
    }

    try {
      this.globalError = null
      this.emailValidationError = null

      const res = await fetch(apiUrl, options)
      const data = await res.json()

      if (res.ok) {
        this.globalError = null
        this.emailValidationError = null

        Object.entries(data).forEach(([key, value]) => {
          this.values[key] = value
        })
      } else {
        if (
          data.code === INVALID_DOMAIN_CODE ||
          data.error === EMAIL_VALIDATION_ERROR ||
          data.code === EMAIL_VALIDATION_CODE
        ) {
          this.emailValidationError = data
        } else {
          this.globalError = data.error
        }
      }
    } catch (error) {
      this.globalError = error
      Bugsnag.notify(error)
    }

    return !this.globalError && !this.emailValidationError
  },

  get isMetric() {
    return this.values.measurementSystem === "metric"
  },

  get isImperial() {
    return this.values.measurementSystem === "imperial"
  },

  goToLogin() {
    const loginUrl = this.values.loginUrl
    this.values = initialState()
    window.location = loginUrl || pipelineConfig.loginUrl
  },

  resetLocalStorage() {
    const initialValues = initialState()
    const valuesToOmit = ["cookiePolicyBannerAccepted"]
    const omittedValues = {}
    Object.keys(this.values).forEach((key) => {
      if (valuesToOmit.includes(key)) {
        omittedValues[key] = this.values[key]
      }
    })

    this.values = {
      ...initialValues,
      ...omittedValues,
    }
    this.storeAnalytics()
  },
}
