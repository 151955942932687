export default ({ targetId, stickyId }) => ({
  target: null,
  sticky: null,

  init() {
    this.target = document.getElementById(targetId)
    this.sticky = document.getElementById(stickyId)

    this.setVisibility()

    window.addEventListener("scroll", () => {
      this.setVisibility()
    })
  },

  setVisibility() {
    const targetBottom = this.target.getBoundingClientRect().bottom
    const isOutOfViewport = targetBottom < 0

    if (isOutOfViewport) {
      this.sticky.classList.add("pipe-sticky-bottom-visible")
    } else {
      this.sticky.classList.remove("pipe-sticky-bottom-visible")
    }
  },

  scrollToElement(id) {
    const element = document.getElementById(id)
    element.scrollIntoView({ behavior: "smooth" })
  },
})
