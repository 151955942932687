const MIN_WEIGHT = 30
const MAX_WEIGHT = 189

const MIN_AGE = 18
const MAX_AGE = 100

const MIN_WEIGHT_LBS = 66
const MAX_WEIGHT_LBS = 415

const validRanges = {
  heightCm: {
    min: 100,
    max: 250,
  },
  heightFt: {
    min: 3,
    max: 8,
  },
  heightIn: {
    min: 0,
    max: 12,
  },
}

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const isString = (value) => typeof value === "string"

const validateMinMax = ({ value, min, max }) => Number(value) >= min && Number(value) <= max

const heightCm = (value, store) => {
  const isValid = validateMinMax({ value, ...validRanges["heightCm"] })
  const isSkipped = store.values.measurementSystem === "imperial"

  return isValid || isSkipped
}

const heightFt = (value, store) => {
  const isValid = validateMinMax({ value, ...validRanges["heightFt"] })
  const isSkipped = store.values.measurementSystem === "metric"

  return isValid || isSkipped
}

const heightIn = (value, store) => {
  const isValid = validateMinMax({ value, ...validRanges["heightIn"] })
  const isSkipped = store.values.measurementSystem === "metric"

  return isValid || isSkipped
}

const validators = {
  measurementSystem: (value) => ["imperial", "metric"].includes(value),
  heightCm,
  heightFt,
  heightIn,
  email: (value) => isString(value) && value.match(EMAIL_REGEX),
  age: (value) => Number(value) >= MIN_AGE && Number(value) <= MAX_AGE,
  targetWeightKg: (value, { isMetric }) =>
    (Number(value) >= MIN_WEIGHT && Number(value) <= MAX_WEIGHT) || !isMetric,
  targetWeightLbs: (value, { isMetric }) =>
    (Number(value) >= MIN_WEIGHT_LBS && Number(value) <= MAX_WEIGHT_LBS) || isMetric,
  weightKg: (value, { isMetric }) =>
    (Number(value) >= MIN_WEIGHT && Number(value) <= MAX_WEIGHT) || !isMetric,
  weightLbs: (value, { isMetric }) =>
    (Number(value) >= MIN_WEIGHT_LBS && Number(value) <= MAX_WEIGHT_LBS) || isMetric,
}

const ERROR_MESSAGES = new Proxy(
  {
    age: "errors.age",
    email: "errors.email",
    heightCm: "errors.heightCm",
    heightFt: "errors.heightFt",
    heightIn: "errors.heightIn",
    weightKg: "errors.weightKg",
    weightLbs: "errors.weightLbs",
  },
  {
    get: (target, name) => (target.hasOwnProperty(name) ? target[name] : "invalid"),
  }
)

const validate = (formValues, store) => {
  const errors = {}
  let hasErrors = false

  Object.entries(formValues).forEach(([field, value]) => {
    const validator = validators[field]

    if (validator && !validator(value, store)) {
      hasErrors = true
      errors[field] = ERROR_MESSAGES[field]
    }
  })

  return { hasErrors, errors }
}

export default validate
