export default (activeStepPath) => ({
  progressWidth: null,

  init() {
    this.progressWidth = this.getProgressWidth(activeStepPath)
  },

  getProgressWidth(activeStepPath) {
    const progressStepsPaths = [
      "/01-step/",
      "/02-step/",
      "/03-step/",
      "/04-step/",
      "/05-step/",
      "/06-step/",
      "/07-step/",
      "/08-step/",
      "/09-step/",
      "/10-step/",
      "/11-step/",
      "/12-step/",
      "/13-step/",
      "/14-step/",
      "/15-step/",
      "/16-step/",
      "/17-step/",
      "/18-step/",
      "/19-step/",
      "/20-step/",
      "/21-step/",
      "/22-step/",
      "/23-step/",
      "/24-step/",
      "/25-step/",
    ]

    const currentStepIndex = progressStepsPaths.indexOf(activeStepPath)

    const currentStepProgress = ((currentStepIndex + 1) * 100) / progressStepsPaths.length
    return currentStepProgress
  },
})
