const EXCLUDE_OTHER_ATTR = "data-exclude-other"

export default (getBasicView) =>
  ({ nextStepPath, formFields }) => ({
    ...getBasicView({ nextStepPath, formFields }),

    isDisabled(input, name, value) {
      const relatedInputs = input.closest("form").querySelectorAll(`input[name="${input.name}"]`)
      const excludingInput = Array.from(relatedInputs).find((el) =>
        el.hasAttribute(EXCLUDE_OTHER_ATTR)
      )

      if (!excludingInput) return false

      const isExcludingSelected = this.formValues[name]?.includes(excludingInput.value)

      return isExcludingSelected && excludingInput !== input
    },

    getFormValues(form) {
      const formData = new FormData(form)
      const newFormValues = {}

      for (const [name, value] of formData.entries()) {
        if (newFormValues[name]) {
          // For multiple fields, the FormData API provides names duplication in entries
          if (Array.isArray(newFormValues[name])) {
            newFormValues[name].push(value)
          } else {
            newFormValues[name] = [newFormValues[name], value]
          }
        } else {
          newFormValues[name] = [value]
        }
      }

      return newFormValues
    },

    updateOtherInputs({ form, input, checked, disabled }) {
      const relatedInputs = form.querySelectorAll(`input[name="${input.name}"]`)
      const otherInputs = Array.from(relatedInputs).filter((el) => el !== input)

      otherInputs.forEach((otherInput) => {
        otherInput.checked = checked
        otherInput.disabled = disabled
      })
    },

    handleFormChange($event) {
      const { currentTarget: form, target: input } = $event

      if (input.hasAttribute(EXCLUDE_OTHER_ATTR)) {
        this.updateOtherInputs({
          form,
          input,
          checked: false,
          disabled: input.checked,
        })
      }

      const newFormValues = this.getFormValues(form)
      this.setSubmitAllowed(newFormValues, formFields)
    },

    submitForm({ currentTarget: form }) {
      const newFormValues = this.getFormValues(form)

      this.formValues = {
        ...this.formValues,
        ...newFormValues,
      }

      this.submit()
    },
  })
