function setRafInterval(callback, delay) {
  let start = Date.now()
  let stop = false

  const intervalFunc = function () {
    const delta = Date.now() - start

    if (delta >= delay) {
      start += delay
      callback()
    }

    stop || requestAnimationFrame(intervalFunc)
  }

  requestAnimationFrame(intervalFunc)

  return {
    clear: function () {
      stop = true
    },
  }
}

function generateRandomProgress(stepsQty) {
  const minStepValue = 18
  const maxStepValue = 25
  const steps = []
  let remainingProgress = 100

  for (let i = 0; i < stepsQty - 1; i++) {
    // Ensure remainingProgress is sufficient for the remaining steps
    const maxAllowedValue = Math.min(maxStepValue, Math.floor(remainingProgress / (stepsQty - i)))
    const step = Math.floor(Math.random() * (maxAllowedValue - minStepValue + 1)) + minStepValue
    steps.push(step)
    remainingProgress -= step
  }

  steps.push(remainingProgress > 0 ? remainingProgress : 0)

  return steps
}

const stepsColors = ["#E5BF94", "#E5BF94", "#E5BF94", "#E5BF94", "#E5BF94", "#E5BF94"]

export default () => ({
  isLoading: true,
  percents: 0,
  step: 0,
  stepColor: stepsColors[0],

  init() {
    this.renderLoadingAnimation()
  },

  renderLoadingAnimation() {
    const stepsQty = 4
    const animationDuration = 3000
    const animationInterval = animationDuration / stepsQty

    const progressSteps = generateRandomProgress(stepsQty)
    let iterations = progressSteps.length
    let step = 0

    const animation = setRafInterval(() => {
      if (step >= iterations) {
        animation.clear()

        // Add extra step to show the post-animation message
        step++
        this.step = step
        this.stepColor = stepsColors[step]
        this.isLoading = false
        return
      }

      this.percents = this.percents + progressSteps[step]
      step++
      this.step = step
      this.stepColor = stepsColors[step]
    }, animationInterval)
  },

  renderMessage(...steps) {
    return steps.includes(this.step)
  },
})
