const ACTIVE_CLASS = "pipe-active"

export default (id, isAuto) => ({
  currentIndex: 1,
  slider: null,
  slidesContainer: null,
  slides: null,
  prevBtn: null,
  nextBtn: null,
  circleButtonsContainer: null,
  circleButtons: null,

  init() {
    this.slider = document.getElementById(id)
    this.slidesContainer = this.slider.querySelector(`#${id}-container`)
    this.slides = this.slidesContainer.querySelectorAll(`[data-slide-index]`)
    this.prevBtn = this.slider.querySelector(`#${id}-prev-btn`)
    this.nextBtn = this.slider.querySelector(`#${id}-next-btn`)
    this.circleButtonsContainer = this.slider.querySelector(`#${id}-slides-btns`)
    this.circleButtons = this.circleButtonsContainer.querySelectorAll(`[data-slide-btn-index]`)

    this.circleButtons.forEach((button) => {
      button.addEventListener("click", () => {
        const index = button.dataset.slideBtnIndex
        this.goToSlide(Number(index))
      })
    })

    this.prevBtn.addEventListener("click", () => {
      this.goToSlide(this.currentIndex - 1)
    })

    this.nextBtn.addEventListener("click", () => {
      this.goToSlide(this.currentIndex + 1)
    })

    this.setClasses(this.currentIndex)

    if (isAuto) {
      // Requirement:
      // First slide should occur quickly (2-4 sec) to let users quickly
      // understand that content can be slided by themselves
      setTimeout(() => {
        requestAnimationFrame(() => {
          if (this.currentIndex !== 1) return

          this.goToSlide(this.currentIndex + 1)
        })
      }, 3000)

      setInterval(() => {
        requestAnimationFrame(() => {
          this.goToSlide(this.currentIndex + 1)
        })
      }, 30000)
    }
  },

  goToSlide(index) {
    if (index <= 0) {
      index = this.slides.length
    } else if (index > this.slides.length) {
      index = 1
    }

    // Move slide
    const translateValue = `-${(index - 1) * 100}%`

    requestAnimationFrame(() => {
      this.slidesContainer.style.transform = `translateX(${translateValue})`
    })

    this.setClasses(index)

    // Update index
    this.currentIndex = Number(index)
  },

  setClasses(index) {
    // Control slides classes
    const oldActiveSlide = this.slidesContainer.querySelector(
      `[data-slide-index="${this.currentIndex}"]`
    )
    const newActiveSlide = this.slidesContainer.querySelector(`[data-slide-index="${index}"]`)
    oldActiveSlide.classList.remove(ACTIVE_CLASS)
    newActiveSlide.classList.add(ACTIVE_CLASS)

    // Control buttons classes
    const oldActiveBtn = this.circleButtonsContainer.querySelector(
      `[data-slide-btn-index="${this.currentIndex}"]`
    )
    const newActiveBtn = this.circleButtonsContainer.querySelector(
      `[data-slide-btn-index="${index}"]`
    )
    oldActiveBtn.classList.remove(ACTIVE_CLASS)
    newActiveBtn.classList.add(ACTIVE_CLASS)
  },
})
