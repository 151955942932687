const KGS_IN_LBS = 0.453592
const LBS_IN_KG = 2.20462

export const kgToLbs = (kg = 0) => Math.round(kg / KGS_IN_LBS)
export const lbsToKg = (lbs = 0) => Math.round(lbs / LBS_IN_KG)

export default (getView) =>
  ({ nextStepPath, formFields }) => ({
    ...getView({
      nextStepPath,
      formFields,
    }),

    handleUnitsChange(units) {
      this.setMeasurementSystem(units)
      this.convertWeight()
      this.setSubmitAllowed(this.formValues, formFields)
    },

    convertWeight() {
      if (this.isImperial()) {
        // Don't do anything if there is nothing to convert
        // to prevent invalid values to appear
        if (!this.formValues.targetWeightKg && this.formValues.targetWeightKg !== 0) return

        this.formValues.targetWeightLbs = kgToLbs(this.formValues.targetWeightKg)
      }

      if (this.isMetric()) {
        if (!this.formValues.targetWeightLbs && this.formValues.targetWeightLbs !== 0) return

        this.formValues.targetWeightKg = lbsToKg(this.formValues.targetWeightLbs)
      }
    },

    // Perform final convertions before submit
    processFormValues() {
      const { targetWeightLbs, targetWeightKg } = this.formValues

      if (this.isMetric()) {
        return {
          targetWeightKg,
          targetWeightLbs: kgToLbs(targetWeightKg),
        }
      }

      if (this.isImperial()) {
        return {
          targetWeightKg: lbsToKg(targetWeightLbs),
          targetWeightLbs,
        }
      }
    },

    setSubmitAllowed(formValues, formFields) {
      const requiredFields =
        this.store.values.measurementSystem === "imperial"
          ? ["targetWeightLbs"]
          : ["targetWeightKg"]

      const allRequiredPresent = requiredFields.every((value) => {
        const formValue = formValues[value]

        if (Array.isArray(formValue)) return !!formValue.length

        return formValue && formValue !== 0
      })

      this.isSubmitAllowed = allRequiredPresent

      // Reset validation errors on units change to not confuse user
      // because we perform validation only on form submit
      formFields.forEach((field) => (this.errors[field] = null))
    },
  })
