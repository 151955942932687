import { createPrimerCheckout, createRedirectCheckout } from "../payments"

export default ({ nextStepPath, paymentMethods, isAbandonedBasket } = {}) => ({
  paymentMethods: [],
  offers: [],
  activeOffer: null,
  showSpinner: false,
  showFetchError: false,
  paymentError: false,
  privacyAgreementError: false,

  init() {
    const searchParams = new URLSearchParams(window.location.search)
    const paymentError = searchParams.get("error")
    if (paymentError) {
      this.paymentError = paymentError
    }

    this.store = Alpine.store("data")

    const offers = pipelineConfig.offers.sort(
      (a, b) => a.customData.sequence - b.customData.sequence
    )
    const storedOffer = this.store.values.activeOffer
    const selectedOffer =
      storedOffer || offers.find((offer) => offer.customData.default_select === "true")

    this.offers = offers
    this.activeOffer = selectedOffer

    this.privacyAgreementError = false

    if (paymentMethods) {
      this.paymentMethods = paymentMethods.split(",")
      this.trackVisitCheckout()
    }

    if (isAbandonedBasket) {
      this.updateOrderUtms()
    }
  },

  isOfferActive(offer) {
    return offer.brandOfferId === this.activeOffer.brandOfferId
  },

  getFormattedPriceWithoutDiscount() {
    return (
      this.activeOffer.customData.symbol + this.activeOffer.customData.total_price_without_discount
    )
  },

  getFormattedDiscount() {
    return this.activeOffer.customData.symbol + this.activeOffer.customData.total_price_discount
  },

  getFormattedTotal() {
    return this.activeOffer.customData.symbol + this.activeOffer.customData.total_price
  },

  isBiggestSave(offer) {
    const discount = parseInt(offer.customData.discount)
    const othersDiscounts = this.offers.map((o) => parseInt(o.customData.discount))
    const max = Math.max(...othersDiscounts)

    // Cosider that biggest value should not have duplicates
    return (
      discount === max &&
      othersDiscounts.lastIndexOf(discount) === othersDiscounts.indexOf(discount)
    )
  },

  scrollToElement(id) {
    const element = document.getElementById(id)
    element.scrollIntoView({ behavior: "smooth" })
  },

  selectOffer(offer) {
    this.activeOffer = offer
    this.store.values.activeOffer = offer
  },

  createRedirectCheckout(paymode) {
    createRedirectCheckout(paymode)
  },

  async submitOffer() {
    if (!this.store.values.privacyAgreement) {
      this.privacyAgreementError = true
      return
    }

    this.privacyAgreementError = false
    this.showSpinner = true

    try {
      await createPrimerCheckout()

      window.location = nextStepPath
      this.showSpinner = false
    } catch (error) {
      console.error("Order submit failed:", error)
      this.showSpinner = false
    }
  },

  async completePayment() {
    this.store.values.completedAt = new Date()

    await this.store.push()
  },

  async trackVisitCheckout() {
    this.store.values.status = "VISIT_CHECKOUT"
    await this.store.push()
  },

  async updateOrderUtms() {
    await this.store.push()
  },
})
