export default () => ({
  init() {
    this.store = Alpine.store("data")

    // I Safari, the init method is not invoked when going back
    // so this hack is needed to avoid bugs
    window.onpageshow = () => {
      // Always reset on init according to requirements
      this.store.values.privacyAgreement = false

      // Set input checked state manually, to avoid out-of-sync
      // behavior when browser renders cached page (when going back)
      const privacyAgreementInput = document.getElementById("privacyAgreementInput")
      privacyAgreementInput.checked = this.store.values.privacyAgreement
    }
  },

  togglePrivacyAgreement($event) {
    this.store.values.privacyAgreement = $event.target.checked
  },
})
