const KGS_IN_LBS = 0.453592

export default () => ({
  bmiPointerDeg: "-90",

  init() {
    this.store = Alpine.store("data")

    setTimeout(() => {
      this.bmiPointerDeg = this.getBmiPointerDeg()
    }, 500)
  },

  isMetric() {
    return this.store.values.measurementSystem === "metric"
  },

  getNormalizedHeight() {
    if (this.isMetric()) {
      return this.store.values.heightCm
    } else {
      return this.store.values.heightFt * 30.48 + this.store.values.heightIn * 2.54
    }
  },

  getNormalizedWeight() {
    if (this.isMetric()) {
      return this.store.values.weightKg
    } else {
      return this.store.values.weightLbs * KGS_IN_LBS
    }
  },

  getBmi() {
    return (this.getNormalizedWeight() / (this.getNormalizedHeight() / 100.0) ** 2).toFixed(1)
  },

  getBmiCategory() {
    const BMI = this.getBmi()

    if (BMI <= 24.9) {
      return "normal"
    } else if (BMI >= 25 && BMI <= 29.9) {
      return "overweight"
    } else if (BMI >= 30 && BMI <= 34.9) {
      return "obese"
    } else if (BMI >= 35 && BMI <= 39.9) {
      return "severely_obese"
    } else {
      return "morbidly_obese"
    }
  },

  isBmiCategory(category) {
    return this.getBmiCategory() === category
  },

  getBmiPointerDeg() {
    const degrees = {
      normal: "-75",
      overweight: "-35",
      obese: "0",
      severely_obese: "35",
      morbidly_obese: "75",
    }
    return degrees[this.getBmiCategory()] || 0
  },
})
